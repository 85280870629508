@import '../../../../shared/styles/image.scss';

.imageContainer {
  @include coverImage;
}
.helperText {
  display: inline-block;
  text-align: center;
  width: 100%;
}
