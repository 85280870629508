$quizPadding: 40px;
$footerHeight: 55px;

$buttonHeightSm: 50px;
$buttonHeightMd: 60px;
$buttonHeightLg: 70px;

:export {
  quizPadding: $quizPadding;
  buttonHeightSm: $buttonHeightSm;
  buttonHeightMd: $buttonHeightMd;
  buttonHeightLg: $buttonHeightLg;
}
