.slider {
}

.thumb {
  cursor: pointer;
  /*color for the thumb */
  background: #ffff;
  z-index: 100;
  /* shape of the thumb: circle */
  width: 25px;
  height: 25px;
  transform: translate(0px, -10px);
  border-radius: 100%;
  border: 1px solid black;
  /* remove default outline when selected */
  outline: none;
}

.track {
  height: 5px;
  width: 100%;
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.8)
  );
}
.track.track-1 {
  display: none;
}

.mark {
  cursor: pointer;
  width: 10px;
  height: 10px;
  transform: translate(7.5px, -2.5px);
  border-radius: 100%;
  border: 1px solid black;
  background-color: #ffff;
}
