@mixin imageContainer {
  width: 100%;
  padding-top: 100%;
  position: relative;
}

@mixin image {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  // TODO incosistent
  // border-radius: 4px;
}

@mixin coverImage {
  @include imageContainer();

  .image {
    @include image;
    object-fit: cover;
    object-position: center;
  }
}

@mixin containImage {
  @include imageContainer();

  .image {
    @include image;
    object-fit: contain;
    object-position: center;
  }
}
