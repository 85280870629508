@import '../../common/_styles/colors';

@mixin headerTitle() {
  font-size: 35px;
  line-height: 35px;
  color: $navi-blue;
  margin-bottom: 60px;
}

.container {
  display: flex;
  background-image: url('./assets/background.jpg');
  width: 100vw;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .formContainer {
    background-color: white;
    padding: 70px;
    text-align: center;
    display: flex;
    margin: auto;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
    .form {
      width: 360px;
      .headerTitle {
        @include headerTitle();
      }
      .headerTitleRedirect {
        @include headerTitle();
        margin-bottom: 30px;
      }
      .formTitle {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        align-items: center;
        color: $gray-1;
        display: flex;
        margin-bottom: 20px;
        text-align: left;
      }
      .alert {
        text-align: left;
        margin-bottom: 10px;
      }
      .loginFormRemember {
        float: left;
        margin-bottom: 0;
      }
      .loginFormForgot {
        float: right;
      }
      .submitButton {
        margin-bottom: 0;
      }
    }
  }
}
