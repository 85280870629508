$header-height: 50px;
.container {
  min-height: 100vh;

  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: $header-height;
    color: #ffffff;
    font-size: 22px;
    line-height: $header-height;
    padding: 0 20px;

    .actions {
      display: flex;
      align-items: baseline;

      .languageSelect {
        margin: 0 10px;
      }
    }
  }
  .layout {
    height: calc(100vh - #{$header-height});

    .mainContent {
      overflow: auto;
      padding: 20px;
    }
  }
}
