.container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .header {
    margin: 0;
  }
  .descriptions {
    padding: 20px;
    background-color: white;
    .uploadResults {
      float: right;
    }
    .editButton {
      float: right;
      span {
        text-decoration: underline;
      }
    }
  }
  .table {
    height: 100%;
  }
}
