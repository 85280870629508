.container {
  overflow-y: auto;
  .form {
    margin: 20px 0;
    padding: 20px;
    background-color: white;
    .divider {
      margin: 5px 0;
    }
    .fields {
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 33%;
        padding: 5px;
        margin-bottom: 10px;
      }
    }
    .rowCheckBoxes {
      display: flex;
      flex-direction: column;
    }
  }
  .submitButton {
    width: fit-content;
    margin-right: 10px;
    margin-left: auto;
  }
}