.alert {
  text-align: left;
  margin-bottom: 10px;

  .list {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
  }

  .paragraph {
    margin: 0px;
  }
}
