@import '../../shared/styles/variables.module.scss';

.customButton {
  display: flex;
  align-items: center;
  margin: 0 auto;
  font-size: 24px;
  height: #{$buttonHeightSm};

  // FIX on wider screens
  // @media screen and (min-width: 768px) {
  //   height: #{$buttonHeightMd}px;
  // }

  // @media screen and (min-width: 1024px) {
  //   height: #{$buttonHeightLg}px;
  // }
}
