@import '../../_styles/pageTile.scss';

.filter {
  @include pageTile;
  .header {
    margin: 0px;
  }
}

.submit {
  margin: 0px;
}
