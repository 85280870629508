@import '../_styles/pageTile.scss';

.table {
  @include pageTile;
  .header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .selectAllButton {
    padding: 0;
  }
}
