.formItem {
  margin-bottom: 0px;
  .answerButton {
    padding: 0px;
    width: 100%;
    height: 100%;
    // TODO border radius conected with image radiu
    // border-radius: 8px;
  }
}

// .emojiAnswerButtonContainer {
//   margin: 0px;

//   .emojiAnswerButton {
//     border-width: 3px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
// }
