@import './shared/styles/image.scss';
@import './shared/styles/variables.module.scss';

$contentPaddingTopSm: 50px;
$contentPaddingTopMd: 60px;
$contentPaddingTopLG: 80px;

@mixin button {
  height: $buttonHeightSm;

  // FIX on wider screens
  // @media screen and (min-width: 768px) {
  //   height: #{$buttonHeightMd}px;
  // }

  // @media screen and (min-width: 1024px) {
  //   height: #{$buttonHeightLg}px;
  // }
}

.wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  flex-grow: 1;
  margin: 0 auto;
  background-color: #ffffff;

  &.rounded {
    border-radius: 1em 1em 0 0;
  }

  .content {
    position: relative;
    display: flex;
    flex: 1;
    flex-flow: column;
    z-index: 1;
    margin: 0 auto;
    max-width: 600px;
    // FIX
    // max-width: 1440px;
    padding: $quizPadding;
    overflow-y: scroll;

    @media (max-width: 600px) {
      max-width: 100vw;
    }

    .background {
      position: absolute;
      top: 0;
      right: 0;
      width: 40%;
      height: 100%;
      background-image: url('./assets/mobile-background.svg');
      background-position: top 55% center;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: -1;

      @media screen and (min-width: 375px) {
        background-position: top 45% center;
      }

      @media screen and (min-width: 420px) {
        background-position: top 35% center;
      }
    }

    .title {
      font-weight: 500;
      margin-top: 0;
      padding-top: calc(#{$contentPaddingTopSm} - #{$quizPadding});

      // FIX on wider screens
      // @media screen and (min-width: 768px) {
      //   padding-top: calc(#{$contentPaddingTopMd} - #{$quizPadding});
      // }

      // @media screen and (min-width: 1024px) {
      //   padding-top: calc(#{$contentPaddingTopLG} - #{$quizPadding});
      // }

      .boldTitle {
        font-weight: 700;
      }
    }

    .paragraph {
      margin: 0.5em 3em;

      .button {
        @include button;
      }
    }

    .buttonContainer {
      text-align: center;

      .button {
        @include button;
      }
    }

    .listContainer {
      text-align: center;
    }

    .imageContainer {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      align-self: flex-end;
      align-content: flex-end;

      &.transformed {
        transform: translate(#{$quizPadding}, #{$quizPadding});
      }
    }

    .questionsContainer {
      flex: 1;
      flex-flow: column;
      padding-bottom: calc(#{$buttonHeightSm} + #{$quizPadding});
    }
  }

  .radio {
    display: flex;
    justify-content: center;
  }
}
