@import '../../pages/Quiz/shared/styles/variables.module.scss';

$header-height-sm: 55px;
$header-height-md: 60px;
$header-height-lg: 80px;

.container {
  min-height: 100vh;
  font-weight: 400;

  .header {
    display: flex;
    justify-content: space-between;

    height: $header-height-sm;
    padding: 0 20px;

    // FIX on wider screens
    // @media screen and (min-width: 768px) {
    //   height: $header-height-md;
    // }

    // FIX on wider screens
    // @media screen and (min-width: 1024px) {
    //   height: $header-height-lg;
    // }

    .headerText {
      display: flex;
      align-items: center;
    }
  }
  .footer {
    height: #{$footerHeight};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #91EE91;

    .footerText {
      font-size: 0.875rem;
    }

  }
  .layout {
    height: calc(100vh - #{$header-height-sm} - #{$footerHeight});
    display: flex;
    overflow-y: hidden;
  }
}
