.sider {
  background-color: white;
}
.trigger {
  width: 100%;
  position: absolute;
  padding: 0px 16px;
  height: 40px;
  bottom: 0px;
  display: flex;
  align-items: center;
  box-shadow: inset 0 1px 0 #f0f0f0;
}