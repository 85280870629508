.container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  .breadcrumb {
    margin-bottom: 10px;
  }
  .descriptions {
    width: 50%;
    margin: 20px 0;
    padding: 20px;
    background-color: white;
  }
  .table {
    background-color: white;
    padding: 20px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;
      .buttons {
        display: flex;
      }
    }
  }
}

.componentToPrint {
  display: none;
}