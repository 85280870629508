@import '../../../../shared/styles/image.scss';

.imageContainer {
  @include coverImage;

  .coveredImage {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    // TODO radius conected with image radius
    // border-radius: 4px;

    .text {
      margin: 0px;
    }
  }
}

.helperText {
  display: inline-block;
  min-height: 1em;
  font-size: 1em;
  text-align: center;
  width: 100%;
}
